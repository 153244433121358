@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

[x-cloak] {
    display: none;
}
.content{
    overflow-y: scroll!important;
}

/** Datatable **/
.id_col, .actions_col{
    width: 0;
}
[id^=datatable-] .shadow {
    box-shadow: none !important;
}
[id^=datatable-] table {
    border-spacing: 0 5px;
    border-collapse: separate;
}
[id^=datatable-] table > thead {
    background: transparent;
}
[id^=datatable-] table > thead th {
    background: transparent;
    text-transform: unset;
    font-weight: initial;
    font-size: 13px;
    color: #333;
}
[id^=datatable-] table > thead .uppercase {
    text-transform: unset;
    font-weight: initial;
    font-size: 13px;
    color: #333;
}

[id^=datatable-] table > thead th{
    /*padding: 0.5rem 0.5rem!important;*/
    text-transform: capitalize!important;
    white-space: nowrap!important;
}
[id^=datatable-] table > tbody > tr:hover > td {
    background: #e2e8f0;
}
[id^=datatable-] table > tbody > tr > td {
    background: #fff;
}
[id^=datatable-] table > tbody > tr > td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
[id^=datatable-] table > tbody > tr > td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
[id^=datatable-] table > tfoot tr {
    background: transparent !important;
}
[id^=datatable-] table > tfoot tr td {
    background: transparent !important;
}
